<template>
  <div class="synopsis-container">
    <div class="image" @click="to"><img :src="data.cover" /></div>
    <div class="t" @click="to">{{ data.name }}</div>
    <div class="username">{{ data.username }}</div>
    <div class="bottom">
      <span>
        <img :src="icon" />
        {{ data.play_mun | playNum }}
      </span>

      <!--      <span>{{ data.time }}</span>-->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
    icon: {
      type: String,
      default() {
        return require("../../assets/home/6.png");
      },
    },
  },
  filters: {
    playNum(v) {
      if (v === null) return 0;
      let r = parseFloat(v);
      return r > 10000 ? (r / 10000).toFixed(2) + "万" : r;
    },
  },
  methods: {
    to() {
      this.$emit("to", this.data);
      // this.$router.push(`/singleDetail/${this.data.id}`);
    },
  },
};
</script>
<style scoped lang="scss">
.synopsis-container {
  width: 285px;
}
.image {
  width: 285px;
  height: 285px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.t {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    color: #c20c0c;
  }
}
.username {
  color: #a0a2a6;
  margin: 5px 0;
}
.bottom {
  //margin-top: 12px;
  display: flex;
  justify-content: space-between;

  > span {
    //display: inline-block;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #a0a2a6;
    display: flex;
    align-items: center;
  }
  img {
    width: 15px;
    margin-right: 8px;
  }
}
</style>
