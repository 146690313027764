<template>
  <div>
    <div
      class="main"
      :style="`grid-template-columns: repeat(${grid.column}, ${grid.cell});`"
    >
      <com-synopsis-big
        class="mr"
        v-for="(item, index) in list"
        :key="index"
        :data="item"
        style="margin-bottom: 50px"
        @to="$router.push(`/${to}/${item.id}?name=${item.name}`)"
      ></com-synopsis-big>
    </div>
    <div class="data-null" v-if="list.length === 0">
      <img src="../../assets/null.png" />
      <div>没有相关歌曲</div>
    </div>
    <com-page
      :total="total"
      layout="prev, pager, next"
      :page-size="pageSize"
      :currentPage="currentPage"
      @change="pageChange"
    ></com-page>
  </div>
</template>
<script>
import comSynopsisBig from "@/components/com/com-synopsis-big";
import comPage from "@/components/com/com-page";
export default {
  components: {
    comSynopsisBig,
    comPage,
  },
  props: {
    list: Array,
    pageSize: {
      type: Number,
      default: 30,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: Number,
    grid: {
      type: Object,
      default() {
        return {
          column: 5,
          cell: "20%",
        };
      },
    },
    to: {
      type: String,
      default: "songsDetail",
    },
  },
  data() {
    return {};
  },
  computed: {
    // currentList() {
    //   return this.list.slice(
    //     (this.currentPage - 1) * this.pageSize,
    //     this.currentPage * this.pageSize
    //   );
    // },
  },
  methods: {
    pageChange(currentPage) {
      this.$emit("currentPageChange", currentPage);
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  //display: flex;
  //flex-wrap: wrap;
  display: grid;
  //grid-template-columns: repeat(5, 20%);
  > .mr {
    //margin-right: 20px;
  }
}
.data-null {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #696b6f;
  img {
    width: 300px;
    margin: 20px 0;
  }
}
</style>
