import { render, staticRenderFns } from "./com-synopsis-big.vue?vue&type=template&id=3a2689a1&scoped=true&"
import script from "./com-synopsis-big.vue?vue&type=script&lang=js&"
export * from "./com-synopsis-big.vue?vue&type=script&lang=js&"
import style0 from "./com-synopsis-big.vue?vue&type=style&index=0&id=3a2689a1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2689a1",
  null
  
)

export default component.exports