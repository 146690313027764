<template>
  <div style="margin-top: 88px">
    <com-tab
      :t="name"
      :buttons="['歌曲', '歌单']"
      :currentIndex.sync="currentIndex"
    ></com-tab>
    <div style="margin-bottom: 20px">
      <com-button
        v-if="currentIndex === 0"
        tyle="allPlay"
        :icon="require('../assets/play.png')"
        @click="playAll"
        >全部播放</com-button
      >
    </div>

    <table-single
      v-loading="loading"
      v-if="currentIndex === 0"
      :list="list"
      :image="true"
      :type="true"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @currentPageChange="pageChang"
    ></table-single>

    <table-img
      v-if="currentIndex === 1"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @currentPageChange="pageChang"
      :list="list"
      :grid="{ column: 4, cell: '25%' }"
      v-loading="loading"
    ></table-img>
  </div>
</template>

<script>
import tableSingle from "@/components/table/table-single";
// import tableSongs from "@/components/table/table-songs";
import tableImg from "@/components/table/table-img";
import comTab from "@/components/com/com-tab";
import comButton from "@/components/com/com-button";
import { Single } from "@/class/music";
export default {
  data() {
    return {
      currentIndex: 0,
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  components: {
    tableSingle,
    tableImg,
    comTab,
    comButton,
  },
  watch: {
    currentIndex: {
      handler(v) {
        this.$router.push({ query: { ...this.$route.query, form: v } });
      },
    },
    send: {
      immediate: true,
      handler() {
        this.currentPage = 1;
        if (this.form == "0") {
          this.sendGetList();
        } else {
          this.sendGetSongsList();
        }
      },
    },
    id: {
      immediate: true,
      handler() {
        this.currentIndex = 0;
      },
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    form() {
      return this.$route.query.form || 0;
    },
    send() {
      let { id, form } = this;
      return { id, form };
    },
    name() {
      return this.$route.query.name;
    },
  },
  methods: {
    sendGetList() {
      this.loading = true;
      this.$api
        .singleList({
          typeId: this.id,
          size: this.pageSize,
          current: this.currentPage,
          shelves: 1,
        })
        .then((resolve) => {
          this.loading = false;
          this.total = resolve.data.total;
          this.list = resolve.data.records.map((item) => {
            item.name = item.musicName;
            item.type = item.musicTypeName;
            return new Single(item);
          });
        })
        .catch(() => (this.loading = false));
    },
    sendGetSongsList() {
      this.loading = true;
      this.$api
        .songsList({
          typeId: this.id,
          size: this.pageSize,
          current: this.currentPage,
        })
        .then((resolve) => {
          this.loading = false;
          this.total = resolve.data.total;
          this.list = resolve.data.records.map((item) => {
            return {
              cover: item.cover,
              singer: item.singer,
              name: item.name,
              songs: item.musicTypeName,
              time: item.gmtCreate,
              username: item.userName,
              id: item.id,
              play_mun: item.num,
            };
          });
        })
        .catch(() => (this.loading = false));
    },
    pageChang(currentPage) {
      this.currentPage = currentPage;
      this.sendGetList();
    },
    playAll() {
      this.$store.commit("ADD_PLAYLIST", this.list);
    },
  },
  created() {
    let form = this.$route.query.form;
    this.currentIndex = form ? parseInt(form) : 0;
  },
};
</script>
